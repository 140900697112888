
import { Options, Vue } from 'vue-class-component';
import * as OM from '@/Model';
import { AppUserClient } from '@/services/Services';
import InfoModal from '@/components/modals/infoModal.vue';

@Options({
    components: {
    }
})
export default class pPofileDetail extends Vue {

    slideIndex: number = 0;
    profile: OM.ProfileSummaryVM = new OM.ProfileSummaryVM();
    identifier: string = "";
    affinityRateText: string = "";

    created(){
        this.affinityRateText = this.$localizationService.getLocalizedValue("app_AffinityRate:", "Affinity Rate:");    
        this.identifier = <string>this.$route.params.identifier;
        this.init();
    }
    
    get affnityFullText(){
        return this.affinityRateText + " " + this.profile.minimumAffinityRate + '%'
    }

    init(){
        AppUserClient.getOtherProfile(this.identifier)
        .then( x => {
            this.profile = x;
        }).catch( err => {
            this.$opModal.show(InfoModal, {
                img: 'face_error.svg',
                text: err.Message,
                confirm: this.$localizationService.getLocalizedValue("app_Riprova", "Riprova"),
                confirmCb: () => {
                    this.$opModal.closeLast();
                    this.init();
                },
                deny: this.$localizationService.getLocalizedValue("app_Indietro", "Indietro"),
                denyCb: () => {
                    this.$opModal.closeLast();
                    this.$router.back();
                },
            })
        })
    }
}
